.mt-4 {
  margin-top: 2rem;
}

.loginForm {
  display: flex;
  flex-direction: column;
  width: 30rem;
  background-color: white;
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.loginForm h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.labelText {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.inputWidth {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.loginErrorMessage {
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  font-size: 0.8rem;
  color: red;
}

.selectOption {
  appearance: menulist;
  -webkit-appearance: menulist;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 1.5rem;
}

.buttonSubmit {
  padding: 0.5rem 2rem;
  background-color: #008080;
  color: white;
  border: 1px solid #008080;
  border-radius: 3px;
  cursor: pointer;
  font-size: 1rem;
}

.buttonSubmit:hover {
  color: #ffffff;
  background-color: #008080;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #008080;
  border-radius: 3px 3px 3px 3px;
}

.signUpContainer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.signUpLink {
  text-decoration: none;
  color: #007bff;
  font-size: 0.9rem;
  cursor: pointer;
}

.signUpLink:hover {
  text-decoration: underline;
}

.selectOption {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  background-color: white;
  background-image: linear-gradient(45deg, transparent 50%, blue 50%),
    linear-gradient(135deg, blue 50%, transparent 50%),
    linear-gradient(to right, skyblue, skyblue);
  background-position: calc(100% - 17px) calc(1em + 2px),
    calc(100% - 13px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}
