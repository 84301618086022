.loginForm {
  width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  text-align: center;
}
.inputWidth {
  width: 100%;
}
.form-group {
  margin-bottom: 15px;
  text-align: left;
}
.buttonSubmit {
  width: 100%;
  padding: 10px;
  margin-top: 15px;
  border: none;
  border-radius: 3px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}
.loginErrorMessage {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  display: block;
}
.mainLoginContainer {
  margin: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(to bottom, #f0f0f0, #d3d3d3); */
}
.signUpContainer {
  text-align: center;
  margin: 20px 0;
}

.signUpText {
  font-size: 18px;
  margin-bottom: 10px;
}

.signUpLink {
  color: #5a7eec;
  cursor: pointer;
  text-decoration: underline;
}

.signUpLink:hover {
  font-weight: bold;
}
.labelText {
  font-weight: bold;
  color: #333;
  font-size: 16px;
  margin-bottom: 8px;
}
