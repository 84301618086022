body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.customer-screen-header {
  background-color: #ccc;
  color: #c3a043;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customer-screen-header h1 {
  margin: 0;
  font-size: 2.5rem;
}

.booking-button {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background: linear-gradient(to right, #e3c363, #c3a043);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.booking-button:hover {
  background: linear-gradient(to right, #c3a043, #e3c363);
}

.logout-button {
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
}
.logout-button svg {
  color: #c3a043;
}

.logout-button svg:hover {
  color: #e3c363;
}

.search-section {
  display: flex;
  background-image: url("https://venuehub.pk/wp-content/uploads/WhatsApp-Image-2022-11-01-at-9.32.12-PM.jpeg");
  width: 100%;
  height: 400px;
  object-fit: fill;
  align-items: center;
  justify-content: center;
}
.filter-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.384);
}
.filter-container h1 {
  color: white;
  font-size: 3.5rem;
  margin-bottom: 30px;
}
.filter {
  display: flex;
}
.filter h4 {
  color: wheat;
  margin: 0 0 5px 10px;
}

.filter-select {
  width: 170px;
  padding: 6px 8px;
  border: 1px solid #7f5a23;
  border-radius: 6px;
  height: 40px;
}
.name-filter {
  padding: 12px 8px;
  width: 170px;
  border: 1px solid #7f5a23;
  height: 40px;
  border-radius: 6px;
}

.search-clear-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  position: relative;
  right: 22px;
}

.data-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem;
}

.data-box {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 300px;
  margin: 1rem;
  padding: 1rem;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.data-box:hover {
  transform: translateY(-5px);
  background-color: whitesmoke;
}

.data-box-image {
  width: 100%;
  height: 200px;
  border-radius: 5px;
  margin-bottom: 1rem;
  object-fit: fill;
}

.data-box-h3 {
  margin: 0;
  margin-bottom: 0.5rem;
}

.data-box p {
  margin: 0;
  margin-bottom: 0.5rem;
}

.data-box-button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.data-box-button:hover {
  background-color: #444;
}
