/* styles.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.admin-panel-header {
  background-color: #ccc;
  color: #e3c363;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

header h1 {
  margin: 0;
}
.notification-dropdown {
  position: relative;
  display: inline-block;
  margin-top: 20px;
}

button {
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.notification {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  padding: 10px;
  margin: 5px 0;
}

.logout-container {
  position: absolute;
  right: 0px;
}
.admin-panel-logout {
  position: absolute;
  right: 37px;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
}
.admin-panel-logout svg {
  color: #c3a043;
}

.admin-panel-logout svg:hover {
  color: #e3c363;
}

.goback-btn {
  position: absolute;
  right: 110px;
}
.banner-section {
  display: flex;
  background-image: url('https://venuehub.pk/wp-content/uploads/WhatsApp-Image-2022-11-01-at-9.32.12-PM.jpeg');
  width: 100%;
  height: 250px;
  object-fit: fill;
  align-items: center;
  justify-content: center;
}
.banner-bg {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.384);
}
.banner-bg h1 {
  color: white;
  font-size: 2.6rem;
}

.data-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem;
}
.nodata-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nodata-error svg {
  font-size: 2rem;
  color: rgb(78, 9, 23);
}

.data-box {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 300px;
  margin: 1rem;
  padding: 1rem;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.data-box:hover {
  transform: translateY(-5px);
  background-color: whitesmoke;
}

.data-box-image {
  width: 100%;
  height: 200px;
  border-radius: 5px;
  margin-bottom: 1rem;
  object-fit: fill;
}

.data-box-h2 {
  margin: 0;
  margin-bottom: 0.5rem;
}

.data-box p {
  margin: 0;
  margin-bottom: 0.5rem;
}

.data-box-hall-btn {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.data-box-hall-btn:hover {
  background-color: #444;
}
.data-box-edit-btn {
  background-color: #424242bd;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.data-box-edit-btn:hover {
  background-color: #4444448a;
}

.open-modal-btn {
  padding: 8px 16px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  background-color: #3498db;
  color: white;
  cursor: pointer;
}

.banquetModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
}
.modal-backdrop-default {
  /* --bs-backdrop-zindex: 1050; */
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.close-modal-btn {
  padding: 8px 16px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  background-color: #e74c3c;
  color: white;
  cursor: pointer;
}

.banquet-register {
  display: flex;
  justify-content: flex-end;
  margin-right: 12px;
}

#logoutButton {
  background-color: #ddd;
  color: #333;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.banquet-actions {
  bottom: 10px;
  left: 10px;
  display: flex;
  gap: 10px;
  justify-content: center;
}
.edit-btn {
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  margin-left: 5px;
}
.edit-btn svg {
  color: blue;
}

.edit-btn svg:hover {
  color: rgba(0, 0, 255, 0.767);
}
.delete-btn {
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  margin-left: 5px;
}
.delete-btn svg {
  color: crimson;
}

.delete-btn svg:hover {
  color: rgba(220, 20, 60, 0.719);
}
