body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ------------------------header------------------------- */
.customer-screen-header {
  background: rgba(255, 255, 255, 0.7);
  color: #c3a043;
  padding: 1px;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customer-screen-header h3 {
  margin: 0;
  font-size: 2.5rem;
}
.PrimaryHeader {
  background: rgba(255, 255, 255, 0.7);
}
.PrimaryHeader img.wlogo {
  max-width: 200px;
}
button.btnRgstr,
button.btnLogin {
  background: linear-gradient(to right, #e3c363, #c3a043);
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  border: none;
}
button.btnRgstr:hover,
button.btnLogin:hover {
  background: linear-gradient(to right, #e3c363, #c3a043);
  color: black;
}
SecondaryHeader .nav-link:focus,
.nav-link:hover {
  color: #e3c363 !important;
  text-decoration: underline;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #e3c363 !important ;
  /* text-decoration: underline; */
}
.ProfileDrp.dropdown .dropdown-menu[data-bs-popper] {
  top: 100%;
  margin-top: var(--bs-dropdown-spacer);
  left: -92px !important;
}
.ProfileDrp.dropdown .btn-success {
  --bs-btn-color: #e3c363;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: none;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #e3c363;
  --bs-btn-hover-border-color: none;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #e3c363;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff !important;
  background-color: #e3c363 !important;
}
/* --------------------------ChatBox-------------------------- */
button.PeopleName {
  font-size: 16px;
  color: black;
  font-weight: 500;
  padding: 4px 8px;
  margin-bottom: 5px;
  background-color: transparent;

  width: 100%;
  border: none;
  text-align: start;
}

button.PeopleName:hover {
  background-color: aquamarine;
  border-radius: 12px;
  color: black;
}
.cahtHeaders {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  padding: 12px;
}
img.personIcon {
  border: 2px solid aquamarine;
  border-radius: 100%;
  width: 40px;
  height: 40px;
}
/* .conversationBox {
  width: 100%;
  overflow-x: hidden;
} */
.sendMsg {
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.sendMsg p.sendText {
  font-size: 12px;
  color: white;
  background-color: blue;
  border-radius: 16px;
  padding: 8px 12px;
  margin: 8px 4px;
  max-width: 200px;
}
.recievedMsg {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-end;
  max-width: 100%;
}
.recievedMsg p.recievedText {
  font-size: 12px;
  color: white;
  background-color: aquamarine;
  border-radius: 16px;
  padding: 8px 12px;
  margin: 8px 4px;
  max-width: 200px;
}
.Chathere {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.ChatBox {
  padding: 10px;
}

/* --------------------Booking Form--------------- */
.HallBookingForm {
  max-width: 500px;
  margin: 0 auto;
}
.HallBookingForm .react-date-picker__wrapper {
  border: thin solid #dee2e6;
  padding: 6px;
  border-radius: 8px;
}
.HallBookingForm label.form-label {
  font-size: 16px;
  font-weight: 500;
}
/* -------------------BeforeLogin------------------- */
/* .BeforeLogin button.btnBeforeLogin {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  background-color: white;
}
.BeforeLogin button.btnBeforeLogin:hover {
  background-color: #0d6efd;
  color: white;
}

.BeforeLogin.modal.show .modal-dialog {
  transform: none;
  max-width: 400px !important;
} */
.BeforeLogin {
  display: flex;
  gap: 12px;
  margin-top: 10;
}

.BeforeLogin .btnBeforeLogin {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  background-color: white;
}

.BeforeLogin .btnBeforeLogin {
  background-color: #0d6efd;
  color: white;
}
.BeforeLogin .google {
  background-color: white;
}

.BeforeLogin .btnBeforeLogin.facebook:hover svg {
  fill: blue;
}

/* -------------------NweBanquetBooking form-------------- */
.NewBanquetBooking {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px 0;
}
.NewBanquetBooking label.form-label {
  font-size: 16px;
  font-weight: 500;
}
.NewBanquetBooking button.buttonSubmit {
  padding: 0.5rem 2rem;
  background-color: #008080;
  color: white;
  border: 1px solid #008080;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
}

.NewBanquetBooking button.buttonSubmit:hover {
  background-color: #008080;
}
/* ------------------BanquetsCard------------------- */
.BanquetCard {
  border: 1px solid #dad9d6;
  border-radius: 16px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}
.BanquetCard:hover {
  transform: translateY(-5px);
}
.BanquetCard img.banquetimg {
  width: 100%;
  border-radius: 16px;
}
.BanquetCard .CardBody {
  padding: 20px 16px;
}
.BanquetCard .CardBody h2 {
  font-size: 20px;
  color: #333333;
  margin-bottom: 16px;
}
.BanquetCard .CardBody span.dtltag {
  font-size: 12px;
  border-radius: 3px;
  background-color: #ebeef1;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.BanquetCard .CardBody h3.Price {
  font-size: 18px;
  color: #333333;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.BanquetCard .CardBody h3.Price span {
  font-size: 18px;
  color: rgba(99, 99, 94, 1);
}
