.banquetModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: white; */
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 85vh;
  width: 400px;
  background: linear-gradient(to right, #ffffff, #f8f8f6, #ffffff);
  overflow: none;
  padding: 20px;
}
.banquetModal::-webkit-scrollbar {
  width: 5px;
}

.banquetModal::-webkit-scrollbar-thumb {
  background-color: #a0a0a0; /* You can change this color */
  border-radius: 5px;
}

.banquetModal::-webkit-scrollbar-track {
  background-color: #f5f5f5; /* You can change this color */
}

.city-area-text {
  font-weight: bold;
  color: #333;
  font-size: 14px;
  margin-bottom: 2px;
}

.fileInputLabel {
  display: block;
  width: 150px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #5a7eec;
  color: white;
  cursor: pointer;
}

.fileInputLabel:hover {
  background-color: #405ec3;
}

.fileInputLabelDisabled {
  background-color: #ccc;
  cursor: not-allowed;
  display: block;
  width: 150px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.fileInput {
  display: none;
}

.inputmaxWidth {
  max-width: 380px;
}

.imageContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.imageContainer > div {
  margin-bottom: 20px;
}

.defaultError {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
