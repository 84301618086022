.contentright {
    display: flex;
    position: absolute;
    right: 0;
    height: 100%;
    width: auto;
    
}

.display-msg {
    width: 100%;
    height: 85%;
}
.margin {
    margin: 0px 7px ;
}

.chat-box {

    width: 500px;
    height: 100%;
    background-color: white;
}
.bottom {
    display: flex;
    position: absolute;
    bottom: 4px;
    align-items: center;

}

.input-msg {
    margin-left: 10px;
    width: 420px;
    height: 45px;
}

.send-msg {
    background: none;
    border: none;
    font-size: 40px;
    cursor: pointer;
    outline: none;
    padding: 0;
    margin: 0;
    margin-left: 5px;
    padding-bottom: 8px;
}

.send-msg svg {
    color: crimson;
}

.send-msg svg:hover {
    color: rgba(220, 20, 60, 0.692);
}
.close-chat-btn {
    background: none;
    border: none;
    font-size: 40px;
    cursor: pointer;
    outline: none;
    padding: 0;
    margin: 0;
}