.header-index {
  z-index: 2;
}

.VanueDetailModal {
  max-width: 100%;
}

.vanue-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bigger-image {
  width: 100%;
  height: 350px;
  filter: brightness(65%);
}

.image-title {
  margin-top: 10px;
  z-index: 1;
  color: whitesmoke;
  position: relative;
  top: -220px;
  text-align: center;
}
.info-color svg {
  color: #c3a043;
}
.info-color {
  background: whitesmoke;
}
.info-color p {
  background: whitesmoke;
  color: black;
}
.buttonSubmit {
  background-color: #c3a043 !important ;
  border: none !important;
}

.buttonSubmit:hover {
  background-color: #e3c363 !important;
}

.custom-datepicker {
  background-color: #f0f0f0;
  font-size: 16px;
  border: 1px solid #ccc;
  justify-content: center;
  align-items: center;
  display: flex;
}
.react-datepicker__input-container {
  /* input {
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
  } */
}
