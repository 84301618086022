.mainResetContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.resetContainer {
  max-width: 400px;
  width: 100%;
  padding: 0 20px;
}

.resetInput {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
}

.resetSubmit {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

/* Media Query for responsiveness */
@media (max-width: 768px) {
  .container {
    max-width: 300px;
  }
}
