.messageInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.senderInfo {
  font-weight: bold;
  margin-right: 0px;
}

.timestamp {
  color: #555;
  font-size: 10px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.recievedText {
  word-wrap: break-word;
}
.sendMsg {
  border-radius: 10px;
  padding: 6px;
}

.adminMessage {
  background-color: #5cb85c;
  color: black;
}

.customerMessage {
  color: #fff; /* White text for customer messages */
}

.senderInfo {
  font-weight: bold;
  color: black;
}

.timestampSender {
  color: #555;
  font-size: 12px;
  display: flex;
  justify-content: start;
  align-items: flex-start;
}

.sendText {
  word-wrap: break-word;
}
