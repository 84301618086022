.halls-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  color: #E3C363;
  background-color: whitesmoke;
}

.halls-logout-button {
  position: absolute;
  right: 37px;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
}
.halls-logout-button svg {
  color: #C3A043;
}

.halls-logout-button svg:hover {
  color: #E3C363;
}

.facilitiesContainer {
  display: flex;
}

.facilityItem {
  margin-right: 10px;
}

.chat-icon {
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  margin-left: 5px;
}

.chat-icon svg {
  color: coral;
}

.chat-icon svg:hover {
  color: rgba(255, 127, 80, 0.61);
}

.edit-btn {
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  margin-left: 5px;
}
.edit-btn svg {
  color: blue;
}

.edit-btn svg:hover {
  color: rgba(0, 0, 255, 0.637);
}
.delete-btn {
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  margin-left: 5px;
}
.delete-btn svg {
  color: crimson;
}

.delete-btn svg:hover {
  color: rgba(220, 20, 60, 0.719);
}
.register-button {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background: linear-gradient(to right, #4e73df, #224abe);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-button:hover {
  background: linear-gradient(to right, #224abe, #4e73df);
}